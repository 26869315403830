// import { TextField, Box, Typography, Button, Grid, CircularProgress } from "@mui/material";
// import PropTypes from "prop-types";
// import { useContext } from "react";
// import { MyContext } from "../Context/websiteinfocontext";

// const Community = ({ className = "" }) => {
//   const { promotext } = useContext(MyContext);
//   console.log("promotext", promotext);
//   return (
//     <Box
//       component="div"
//       sx={{
//         position: "relative",
//         width: "100%",
//         // backgroundColor: "#ff6501",
//         // borderRadius:"10px",
//         padding: {
//           xs: "15px 10px",
//           sm: "20px 15px",
//           md: "25px 20px",
//           lg: "30px 25px",
//         },
//         textAlign: "center",
//         fontFamily: "Poppins",
//         color: "white",
//       }}
//     >
//       {Object.keys(promotext).length <= 0 || promotext === undefined ? (
//         <Grid width={"100%"} mt={2} display={"flex"} justifyContent={"center"}>
//           <CircularProgress color="primary" style={{ color: "#ff6501" }} />
//         </Grid>
//       ) : (
//         <>
//           <Box
//             sx={{
//               position: "absolute",
//               top: 0,

//               borderRadius: "10px",
//               right: 0,
//               bottom: 0,
//               left: 0,
//               backgroundColor: "#ff6501",
//               zIndex: -1,
//             }}
//           />
//           <Grid container spacing={4}>
//             <Grid item xs={12}>
//               <Typography
//                 variant="h1"
//                 sx={{
//                   fontSize: {
//                     xs: "1.2rem",
//                     sm: "1.5rem",
//                     md: "1.9rem",
//                     lg: "2.2rem",
//                   },
//                   letterSpacing: "0.03em",
//                   lineHeight: {
//                     xs: "2.625rem",
//                     sm: "3.5rem",
//                     md: "4.375rem",
//                     lg: "5rem",
//                   },
//                   fontWeight: 900,
//                 }}
//               >
//                 {promotext?.promo_text1}
//                 {/* JOIN SHOPPING COMMUNITY TO GET MONTHLY PROMO */}
//               </Typography>
//             </Grid>
//             <Grid item xs={12}>
//               <Typography
//                 sx={{
//                   fontSize: {
//                     xs: "1.1rem",
//                     sm: "1.2rem",
//                     md: "1.5rem",
//                     lg: "1.8rem",
//                   },
//                   letterSpacing: "0.03em",
//                   lineHeight: {
//                     xs: "1.313rem",
//                     sm: "1.75rem",
//                     md: "2.188rem",
//                     lg: "2.5rem",
//                   },
//                   color: "snow",
//                   textAlign: "center",
//                 }}
//               >
//                 {promotext?.promo_text2}
//                 {/* Type your email down below and be young wild generation */}
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               container
//               xs={12}
//               spacing={2}
//               justifyContent="center"
//               alignItems="center"
//             >
//               <Grid item xs={12} sm={8} md={6} lg={4}>
//                 <TextField
//                   variant="outlined"
//                   fullWidth
//                   sx={{
//                     "& fieldset": { border: "none" },
//                     "& .MuiInputBase-root": {
//                       height: {
//                         xs: "2rem",
//                         sm: "2.4rem",
//                         md: "2.8rem",
//                         lg: "3.2rem",
//                       },
//                       backgroundColor: "#fff",
//                       borderRadius: "5px",
//                     },
//                   }}
//                   placeholder="Add your email here"
//                 />
//               </Grid>
//               <Grid item xs={12} sm={4} md={3} lg={2}>
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   sx={{
//                     height: {
//                       xs: "2rem",
//                       sm: "2.4rem",
//                       md: "2.8rem",
//                       lg: "3.2rem",
//                     },
//                     "&:hover": {
//                       backgroundColor: "#000",
//                       borderColor: "#000",
//                       color: "#ff6501",
//                     },
//                     backgroundColor: "black",
//                     color: "white",
//                     borderRadius: "5px",
//                     fontSize: {
//                       xs: "1rem",
//                       sm: "1.2rem",
//                       md: "1.4rem",
//                       lg: "1.6rem",
//                     },
//                     letterSpacing: "0.03em",
//                     lineHeight: {
//                       xs: "1.313rem",
//                       sm: "1.75rem",
//                       md: "2.188rem",
//                       lg: "2.5rem",
//                     },
//                   }}
//                 >
//                   SEND
//                 </Button>
//               </Grid>
//             </Grid>
//           </Grid>
//         </>
//       )}
//     </Box>
//   );
// };

// Community.propTypes = {
//   className: PropTypes.string,
// };

// export default Community;

import { useState, useContext } from "react";
import { TextField, Box, Typography, Button, Grid, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import { MyContext } from "../Context/websiteinfocontext";

const Community = ({ className = "" }) => {
  const { promotext } = useContext(MyContext);
  console.log("promotext", promotext);

  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const submitEmail = async () => {
    if (!email) {
      alert('Please enter an email address');
      return;
    }
  
    const scriptURL = 'https://script.google.com/macros/s/AKfycbyDPJiH2cuZgnNKRYcuQtQnPExJtvPy4N-7O7UDRvuUWZxDkp2VV8tyNrV0idGzegI-/exec';
  
    try {
      const response = await fetch(scriptURL, {
        method: 'POST',
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
        redirect:"follow",
        // mode:"no-cors",
        body: JSON.stringify({
          email: email,
          })
      });
  console.log("response111",response)
      if (response.ok) {
        alert('Email sent successfully!');
      } else {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        alert('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred');
    }


    // const scriptURL = 'https://script.google.com/macros/s/AKfycbzoJ58KVZthVIFHL6c764vwSHVkLtfOACpmH4NfyPDpyOiXJJ1a-mjp80VTuNRdVQjw/exec';
  
    // try {
    //   const response = await fetch(scriptURL, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //     body: new URLSearchParams({ email: email })
    //   });
  
    //   if (response.ok) {
    //     alert('Email sent successfully!');
    //   } else {
    //     const errorText = await response.text();
    //     console.error('Error response:', errorText);
    //     alert('Failed to send email');
    //   }
    // } catch (error) {
    //   console.error('Error:', error);
    //   alert('An error occurred');
    // }
  };
  

  return (
    <Box
      component="div"
      sx={{
        position: "relative",
        width: "100%",
        padding: {
          xs: "15px 10px",
          sm: "20px 15px",
          md: "25px 20px",
          lg: "30px 25px",
        },
        textAlign: "center",
        fontFamily: "Poppins",
        color: "white",
      }}
    >
      {Object.keys(promotext).length <= 0 || promotext === undefined ? (
        <Grid width={"100%"} mt={2} display={"flex"} justifyContent={"center"}>
          <CircularProgress color="primary" style={{ color: "#ff6501" }} />
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              borderRadius: "10px",
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "#ff6501",
              zIndex: -1,
            }}
          />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: {
                    xs: "1.2rem",
                    sm: "1.5rem",
                    md: "1.9rem",
                    lg: "2.2rem",
                  },
                  letterSpacing: "0.03em",
                  lineHeight: {
                    xs: "2.625rem",
                    sm: "3.5rem",
                    md: "4.375rem",
                    lg: "5rem",
                  },
                  fontWeight: 900,
                }}
              >
                {promotext?.promo_text1 ?promotext?.promo_text1:"Join Community"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: {
                    xs: "1.1rem",
                    sm: "1.2rem",
                    md: "1.5rem",
                    lg: "1.8rem",
                  },
                  letterSpacing: "0.03em",
                  lineHeight: {
                    xs: "1.313rem",
                    sm: "1.75rem",
                    md: "2.188rem",
                    lg: "2.5rem",
                  },
                  color: "snow",
                  textAlign: "center",
                }}
              >
                {promotext?.promo_text2 ? promotext?.promo_text2:"Type your email below"}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={handleEmailChange}
                  sx={{
                    "& fieldset": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: {
                        xs: "2rem",
                        sm: "2.4rem",
                        md: "2.8rem",
                        lg: "3.2rem",
                      },
                      backgroundColor: "#fff",
                      borderRadius: "5px",
                    },
                  }}
                  placeholder="Add your email here"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: {
                      xs: "2rem",
                      sm: "2.4rem",
                      md: "2.8rem",
                      lg: "3.2rem",
                    },
                    "&:hover": {
                      backgroundColor: "#000",
                      borderColor: "#000",
                      color: "#ff6501",
                    },
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "5px",
                    fontSize: {
                      xs: "1rem",
                      sm: "1.2rem",
                      md: "1.4rem",
                      lg: "1.6rem",
                    },
                    letterSpacing: "0.03em",
                    lineHeight: {
                      xs: "1.313rem",
                      sm: "1.75rem",
                      md: "2.188rem",
                      lg: "2.5rem",
                    },
                  }}
                  onClick={submitEmail}
                >
                  SEND
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

Community.propTypes = {
  className: PropTypes.string,
};

export default Community;

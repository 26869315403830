// import React, { useEffect,useState } from 'react'
// import Layout from '../components/Layout'
// import { getAboutUspageinfo, getcontactUspageinfo } from '../helper/utils/landingpageapi';
// import ContactUsForm from '../components/ContactUsForm';
// function ContactUs() {

//   const [contactus, setcontactus] = useState("");
//   useEffect(()=>{

//   getcontactUspageinfo().then((res)=>setcontactus(res?.about))

//   },[])

//   return (
//     <Layout>
//      <ContactUsForm/>
//     </Layout>
//   )
// }

// export default ContactUs
import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { getAboutUspageinfo } from "../helper/utils/landingpageapi";
import {
  Grid,
  CircularProgress,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import FrameComponent from "../components/FrameComponent";
import Teams from "../components/Teams";
import ContactUsForm from "../components/ContactUsForm";

function ContactUs() {
  const [contactus, setContactUs] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const paragraphs = containerRef.current.getElementsByTagName("span");
      for (let p of paragraphs) {
        p.style.fontSize = "1.2rem";
        p.style.fontFamily = "Poppins, sans-serif";
        p.style.color = "#445b4b";
      }
      const paragraph = containerRef.current.getElementsByTagName("p");
      for (let p of paragraph) {
        p.style.marginBottom = "15px";
      }
    }
  }, [loading]);

  useEffect(() => {
   setTimeout(() => {
    setLoading(false)
   }, 1500);
  }, []);

  if (loading) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress color="primary" style={{ color: "#ff6501" }} />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid justifyContent="center" sx={{ padding: 0 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            position: "relative",
            minHeight: "45vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            overflow: "hidden",
          }}
        >
          <img
            src="/pexels-joenibraw-1416530.jpg"
            alt="Background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Typography
            variant="h3"
            component="h1"
            sx={{
              // fontSize: 25,
              fontFamily: "Poppins",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0)", // Optional: adds a slight background to improve text readability
              padding: 1,
              borderRadius: 1,
              zIndex: 1,
            }}
          >
            {" "}
            Contact Us
          </Typography>
        </Grid>
        <Box
          pb={3}
          p={5}
          sx={{
            paddingTop: { xs: "10px", sm: "10px", md: "5px", lg: "10px" },
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            boxSizing: "border-box",
            textAlign: "left",
            fontSize: "var(--font-size-29xl)",
            color: "var(--color-black)",
            fontFamily: "var(--font-poppins)",
            maxWidth: "100%",
            "@media screen and (max-width: 1850px)": {
              flexWrap: "wrap",
            },
            "@media screen and (max-width: 925px)": {
              gap: "var(--gap-32xl)",
            },
            "@media screen and (max-width: 450px)": {
              gap: "var(--gap-6xl)",
            },
          }}
          //   className={className}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%", // Use percentage for responsive width
                maxWidth: "25.688rem", // Set max width to keep it within bounds
                height: "auto", // Auto height to maintain aspect ratio
                aspectRatio: "25.688 / 3.619", // Maintain aspect ratio for responsiveness
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "20%", // Use percentage for positioning
                  left: "18%", // Use percentage for positioning
                  width: "45%", // Use percentage for sizing
                  height: "auto", // Auto height to maintain aspect ratio
                }}
                loading="lazy"
                alt=""
                src="/vector-8.svg"
              />
              <Box
                sx={{
                  margin: 0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  fontSize: "2rem",
                  letterSpacing: "0.06em",
                  lineHeight: "3.125rem",
                  fontWeight: 900,
                  fontFamily: "poppins",
                  display: "inline-block",
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                  "@media screen and (max-width: 925px)": {
                    fontSize: "var(--font-size-19xl)",
                    lineHeight: "2rem",
                  },
                  "@media screen and (max-width: 450px)": {
                    fontSize: "var(--font-size-10xl)",
                    lineHeight: "1.575rem",
                  },
                }}
              >
                Contact
              </Box>
            </Box>
            <Grid p={2}>
              <ContactUsForm  />
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Layout>
  );
}

export default ContactUs;

import { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const FrameComponent = ({
  productImages,
  freshFlavors,
  arrow1,
  propWidth,
  propGap,
  url,
}) => {
  const frameDivStyle = useMemo(
    () => ({
      width: propWidth,
      gap: propGap,
    }),
    [propWidth, propGap]
  );

  return (
    <Card
      sx={{
        ...frameDivStyle,
        padding: 0,
        background: "#f4f6f5",
        borderRadius: "10px",
        border: "1px solid #f7f7f7",
        display: "flex",
        boxShadow: "none",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CardMedia
        component="img"
        image={productImages}
        alt=""
        loading="lazy"
        sx={{
          width:
            freshFlavors == "Puppy care" || freshFlavors == "Kitten care"
              ? "60%"
              : "50%",
          height: "auto",
          objectFit: "cover",
        }}
      />
      <CardContent
        sx={{
          display: "flex",
          padding: 1,
          alignItems: "center",
          width: "100%",
          margin: "auto",
          justifyContent: "space-between",
        }}
      >
        <div onClick={() => window.open(url, { target: "_blank" })}>
          <Typography
            variant="body2"
            sx={{
              mb: 0.5,
              fontFamily: "poppins",
              fontSize: { xs: "1rem", sm: "1rem", md: "1.2rem" },
            }}
          >
            {freshFlavors}
          </Typography>
          <Button
            variant="outlined"
            sx={{
              "&:hover": {
                border: "0.5px solid #ff6501",
                backgroundColor: "transparent",
                color: "#ff6501",
              },
              cursor: "pointer",
              border: "0.5px solid #ff6501",
              mb: 0.5,
              borderRadius: "0.5rem",
              color: "#ff6501",
              fontFamily: "poppins",
              fontSize: { xs: "0.7rem", sm: "1rem", md: "0.9rem" },
            }}
          >
            Explore Now!
          </Button>
        </div>
        <Box>
          <IconButton
            sx={{ pt: 5 }}
            onClick={() => window.open(url, { target: "_blank" })}
          >
            <ArrowForwardIcon
              sx={{
                p: 0,
                fontSize: { xs: "1.4rem", sm: "1.5rem", md: "2rem" },
              }}
            />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

FrameComponent.propTypes = {
  productImages: PropTypes.string,
  freshFlavors: PropTypes.string,
  arrow1: PropTypes.string,
  propWidth: PropTypes.any,
  propGap: PropTypes.any,
};

export default FrameComponent;

import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import _ from "lodash";
import { gettermsandcondistion } from "../helper/utils/landingpageapi";
function Termsandcondistion() {
  const containerRef = useRef(null);
  const [termsAndCondition, setTermsAndCondition] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  useEffect(() => {
    setLoading(true);
    gettermsandcondistion().then((res) => {
      if (res.terms_and_condition) {
        setTermsAndCondition(res.terms_and_condition);
        setLoading(false);
      }
    });
  }, []);


  useEffect(() => {
    if (containerRef.current) {
      const paragraphs = containerRef.current.getElementsByTagName('span');
      for (let p of paragraphs) {
        p.style.fontSize = '1.2rem';
        p.style.fontFamily = 'Poppins, sans-serif';
        p.style.color = '#445b4b';
      }
      const paragraph = containerRef.current.getElementsByTagName('p');
      for (let p of paragraph) {
        p.style.marginBottom = '10px';
      }
    }
  }, [loading]);

  if (loading) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
         <CircularProgress color="primary" style={{ color: "#ff6501" }} />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={11}
          sx={{
            position: "relative",
            minHeight: "55vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            overflow: "hidden",
          }}
        >
          <img
            src="/pexels-rdne-7821937.jpg"
            alt="Background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Typography
            variant="h3"
            component="h1"
            sx={{
              // fontSize: 25,
              fontFamily: "Poppins",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0)", // Optional: adds a slight background to improve text readability
              padding: 1,
              borderRadius: 1,
              zIndex: 1,
            }}
          >
            {/* Our Stores */}
          </Typography>
        </Grid>
      <Grid container justifyContent="center" sx={{ padding: 2 }}>
        <Grid item xs={12} sm={12} md={10} lg={11}>
          <Box
            sx={{
              padding: 2,
              width: "100%",
              backgroundColor: "background.paper",
              boxShadow: 0.5,
              borderRadius: 2,
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              mb={0.5}
              fontSize={25}
              fontFamily={"Poppins"}
            >
              Terms & Conditions
            </Typography>
            <Divider sx={{ mb: 5 }} />
            <Box>
              <div
                ref={containerRef}
                dangerouslySetInnerHTML={{ __html: termsAndCondition }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Termsandcondistion;

import React, { createContext, useState, useContext, useEffect } from "react";
import { getAboutUspageinfo, getCommunitypageinfo, getcontactpageinfo, getcontactUspageinfo, getLandingpageinfo, getLandingpagespecialsale } from "../helper/utils/landingpageapi";

export const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [state, setState] = useState({});
  const [special_sale, setspecial_sale] = useState({});
  const [socialmedia, setsocialmedia] = useState({});
  const [promotext, setaboutus] = useState({});
  const [communitytext, setcommunitytext] = useState({});

  useEffect(() => {
    getLandingpageinfo().then((res) => setState(res));
    getLandingpagespecialsale().then((res) => setspecial_sale(res));
    getcontactpageinfo().then((res) => setsocialmedia(res));
    getCommunitypageinfo().then((res) => setaboutus(res));
  }, []);

  return (
    <MyContext.Provider value={{ state,special_sale,socialmedia,promotext, setState }}>
      {children}
    </MyContext.Provider>
  );
};

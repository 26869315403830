import React from 'react'
import Layout from '../components/Layout'

function Faq() {
  return (
    <Layout>
      Faq
    </Layout>
  )
}

export default Faq

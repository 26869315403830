import React from 'react'
import Layout from '../components/Layout'

function Support() {
  return (
    <Layout>
      Support
    </Layout>
  )
}

export default Support

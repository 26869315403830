import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { getAboutUspageinfo } from "../helper/utils/landingpageapi";
import {
  Grid,
  CircularProgress,
  Typography,
  Box,
  Divider,
  Button,
} from "@mui/material";
import Teams from "../components/Teams";
import Mapcomponent from "../components/Mapcomponent";
import "./Ourteam.css";

function Ourstores() {
  const [contactus, setContactUs] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const paragraphs = containerRef.current.getElementsByTagName("span");
      for (let p of paragraphs) {
        p.style.fontSize = "1.2rem";
        p.style.fontFamily = "Poppins, sans-serif";
        p.style.color = "#445b4b";
      }
      const paragraph = containerRef.current.getElementsByTagName("p");
      for (let p of paragraph) {
        p.style.marginBottom = "15px";
      }
    }
  }, [loading]);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  if (loading) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress color="primary" style={{ color: "#ff6501" }} />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid justifyContent="center" sx={{ padding: 0 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={11}
          sx={{
            position: "relative",
            minHeight: "55vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            overflow: "hidden",
          }}
        >
          <img
            src="/pexels-dhenry-10330689 (1).jpg"
            alt="Background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Typography
            variant="h3"
            component="h1"
            sx={{
              // fontSize: 25,
              fontFamily: "Poppins",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0)", // Optional: adds a slight background to improve text readability
              padding: 1,
              borderRadius: 1,
              zIndex: 1,
            }}
          >
            Our Stores
          </Typography>
        </Grid>
        <Divider />
        <br />
        <Grid p={2}>
          <Box
            sx={{
              position: "relative",
              width: "100%", // Use percentage for responsive width
              maxWidth: "25.688rem", // Set max width to keep it within bounds
              height: "auto", // Auto height to maintain aspect ratio
              aspectRatio: "25.688 / 3.619", // Maintain aspect ratio for responsiveness
            }}
          >
            <img
              style={{
                position: "absolute",
                top: "20%", // Use percentage for positioning
                left: "30%", // Use percentage for positioning
                width: "45%", // Use percentage for sizing
                height: "auto", // Auto height to maintain aspect ratio
              }}
              loading="lazy"
              alt=""
              src="/vector-8.svg"
            />
            <Box
              sx={{
                margin: 0,
                position: "absolute",
                top: 0,
                left: 0,
                fontSize: "2rem",
                letterSpacing: "0.06em",
                lineHeight: "3.125rem",
                fontWeight: 900,
                fontFamily: "poppins",
                display: "inline-block",
                width: "100%",
                height: "100%",
                zIndex: 1,
                "@media screen and (max-width: 925px)": {
                  fontSize: "var(--font-size-19xl)",
                  lineHeight: "2rem",
                },
                "@media screen and (max-width: 450px)": {
                  fontSize: "var(--font-size-10xl)",
                  lineHeight: "1.575rem",
                },
              }}
            >
              Our Stores .
            </Box>
          </Box>

          <Grid
            sx={{
              display: { sm: "flex", md: "flex", lg: "flex" },
              justifyContent: {
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-start",
              },
              alignItems: "center",
            }}
          >
            <Grid xs={12} sm={4} md={4} lg={4} flexDirection={"column"} m={2}>
              <Grid
                style={{
                  backgroundColor: "#F4F6F5",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Mapcomponent
                  marker={{
                    location: {
                      lat: -34.07220354773511,
                      lng: 18.50174135251041,
                    },
                  }}
                />
                <Typography p={2} fontSize={"0.8rem"}>
                  The Vet Store Vangate Mall, Shop 15 & 58A,
                  <br /> Jakes Gerwel Dr,
                  <br />
                  Athlone, Cape Town, 7764,
                  <br /> South Africa
                  <br />
                  +27 21 638 2055 (Vangate)
                </Typography>
              </Grid>
              <Grid textAlign={"center"}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "50px",
                    mt: 1,
                    textTransform: "none",
                    backgroundColor: "#ff6501", // Set the initial background color
                    color: "white", // Set the initial text color
                    "&:hover": {
                      backgroundColor: "#ff6501", // Change background color on hover
                      color: "black", // Change text color on hover
                    },
                  }}
                  onClick={() => window.open('https://www.google.com/maps/search/?api=1&query=-33.961180398604974, 18.53850828131305', '_blank')}

                >
                  Visit us
                </Button>{" "}
              </Grid>
            </Grid>

            <Grid
              xs={12}
              sm={4}
              md={4}
              lg={4}
              //  sx={{display:{md:"flex",sm:"flex",lg:"flex"}}}
              m={2}
            >
              <Grid
                style={{
                  backgroundColor: "#F4F6F5",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Mapcomponent
                  marker={{
                    location: {
                      lat: -34.103991725399681,
                      lng: 18.4348667679467,
                    },
                  }}
                />
                <Typography p={2} fontSize={"0.8rem"}>
                  The Vet Store On Capricorn Shop No. 18, <br />
                  Capricorn Square, Prince George Dr,
                  <br />
                  Muizenberg, Cape Town, 7945,
                  <br /> South Africa <br /> +021 709 0064 (Capricorn)
                </Typography>
              </Grid>
              <Grid textAlign={"center"}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "50px",
                    mt: 1,
                    textTransform: "none",
                    backgroundColor: "#ff6501", // Set the initial background color
                    color: "white", // Set the initial text color
                    "&:hover": {
                      backgroundColor: "#ff6501", // Change background color on hover
                      color: "black", // Change text color on hover
                    },
                  }}
                  onClick={() => window.open('https://www.google.com/maps/search/?api=1&query=-34.08828016624932, 18.481925808310542', '_blank')}

                >
                  Visit us
                </Button>{" "}
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={4}
              md={4}
              lg={4}
              //  sx={{display:{md:"flex",sm:"flex",lg:"flex"}}}
              m={2}
            >
              <Grid
                style={{
                  backgroundColor: "#F4F6F5",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Mapcomponent
                  marker={{
                    location: {
                      lat: -34.0139,
                      lng: 18.7141,
                    },
                  }}
                />
                <Typography p={2} fontSize={"0.8rem"}>
                  The Vet Store Sanbury cnr Baden Powell Dr,
                  <br /> Old Faure Rd, Eerste River,
                  <br /> Cape Town, 7100,
                  <br /> South Africa
                  <br />
                  +27 21 493 3272 (Sanbury)
                </Typography>
              </Grid>
              <Grid textAlign={"center"}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "50px",
                    mt: 1,
                    textTransform: "none",
                    backgroundColor: "#ff6501", // Set the initial background color
                    color: "white", // Set the initial text color
                    "&:hover": {
                      backgroundColor: "#ff6501", // Change background color on hover
                      color: "black", // Change text color on hover
                    },
                  }}
                  onClick={() => window.open('https://www.google.com/maps/search/?api=1&query=-34.024987657618546, 18.739748979471457', '_blank')}

                >
                  Visit us
                </Button>{" "}
              </Grid>
            </Grid>

            <Grid
              xs={12}
              sm={4}
              md={4}
              lg={4}
              // sx={{display:{md:"flex",sm:"flex",lg:"flex"}}}
              m={2}
            >
              <Grid
                style={{
                  backgroundColor: "#F4F6F5",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Mapcomponent
                  marker={{
                    location: {
                      lat: -34.044320594149161,
                      lng: 18.693274351761286,
                    },
                  }}
                />
                <Typography p={2} fontSize={"0.8rem"}>
                  TOPS Circle Centre Shop 27,
                  <br /> Circle Centre Cnr Caledon &, Belvedere St,
                  <br /> Somerset West, 7130,
                  <br /> South Africa
                  <br />
                  +27 21 493 5029 (Somerset West)
                </Typography>
              </Grid>
              <Grid textAlign={"center"}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "50px",
                    mt: 1,
                    textTransform: "none",
                    backgroundColor: "#ff6501", // Set the initial background color
                    color: "white", // Set the initial text color
                    "&:hover": {
                      backgroundColor: "#ff6501", // Change background color on hover
                      color: "black", // Change text color on hover
                    },
                  }}                                                                                
                  onClick={() => window.open('https://www.google.com/maps/search/?api=1&query=-34.08461044327639, 18.853513569319215', '_blank')}

                >
                  Visit us
                </Button>{" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Ourstores;

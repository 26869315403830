export const LANDING_PAGE ={
    WEB_INFO:"/customer/api/website-info/",
    PROMOTION:"/customer/get-shp-images/",
    SPECIAL_SALE:"/customer/get-special-sale/",
    CONATCT:"/customer/api/contact-us/",
    PROMOTEXTCONTENT:"/customer/get-prom-text-content/",
    CONATCTUS:"/customer/api/contact-us/",
    ABOUTUS:"/customer/api/about/",
    TERMSANDCONDN:"/customer/api/terms-&-condition/",
    PRIVACY_POLICY:"/customer/get-privacy-policy-content/"
}
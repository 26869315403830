import React from 'react';
import { Button, Grid } from '@mui/material';

const PetSFoodCategory = () => {
  return (
    <Grid
      container
      spacing={2}
      direction={{ xs: 'column', md: 'row' }} 
      justifyContent="space-around"
      alignItems="center"
      pb={1}
      sx={{ marginTop: {xs:"0px",sm:"0px",md:"10px",lg:"10px"} }}
    >
      <Grid item xs={12} md="auto" sx={{ width: { xs: "100%", md: "auto" }, marginTop: { xs: "5px", md: "0px" } }}>
        <Button
          variant="contained"
          fullWidth
          onClick={()=>window.open("https://shop.thevetstore.co.za/products?category=Dog%20Food",{target:"_blank"})}
          style={{ padding: "10px", fontSize: "1.1rem", background: "#000",fontFamily:"poppins" }}
          startIcon={<img src={"/dog.png"} alt="Dog" style={{ width: 30, height: 25,filter:"invert(100)" }} />}
          >
          For DOGS
        </Button>
      </Grid>
      <Grid item xs={12} md="auto" sx={{ width: { xs: "100%", md: "auto" } }}>
        <Button
          variant="contained"
          fullWidth
          onClick={()=>window.open("https://shop.thevetstore.co.za/products?category=Cat%20Food",{target:"_blank"})}
          style={{ padding: "10px", fontSize: "1.1rem",fontFamily:"poppins", background: "#000" }}
          startIcon={<img src={"/cat.png"} alt="Cat" style={{ width: 30, height: 25 , filter:"invert(100)" }} />}
          >
          For CATS
        </Button>
      </Grid>
      <Grid item xs={12} md="auto" sx={{ width: { xs: "100%", md: "auto" } }}>
        <Button
          variant="contained"
          fullWidth
          onClick={()=>window.open("https://shop.thevetstore.co.za/products?category=Small%20Pets",{target:"_blank"})}
          style={{ padding: "10px", fontSize: "1.1rem",fontFamily:"poppins",  background: "#000" }}
          startIcon={<img src={"/hamster-equipment.png"} alt="Small Pets" style={{ width: 30, height: 25 , filter:"invert(100)"}} />}
        >
          For small PETS
        </Button>
      </Grid>
    </Grid>
  );
};

export default PetSFoodCategory;

import React, { useState } from "react";
import {
  Container,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  Typography,
  Grid,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link } from "react-router-dom";
import LoadingOverlay from "./Loader";

const ContactUsForm = () => {
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    category: "",
    message: "",
    acknowledge: false,
    terms: false,
  });

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };
  // Update form data as the user types/selects
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };




  const handleSubmit = async () => {
    console.log("formData",formData)
    if (!formData.email || !formData.name) {
      alert('Please enter both name and email address');
      return;
    }

    if (validateEmail(formData.email)) {
      setError(false);
    } else {
      setError(true);
      setHelperText('Please enter a valid email');
      return
    }

    const scriptURL = 'https://script.google.com/macros/s/AKfycbzCNSDxejqnoCKLxdTNlhiwZfpqS0Er5EYkV7Wo8V_sxfrSv0rcbNlaaeqYdzELUURz/exec';
    
    try {
    setLoading(true)
    const response = await fetch(scriptURL, {
      method: 'POST',
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
      redirect:"follow",
      // mode:"no-cors",
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message,
        category: formData.category,
        terms: formData.terms.toString(),
        acknowledge:formData.acknowledge.toString()
      })
    });
console.log("response",response)
    if (response.ok) {
      alert('Data submitted successfully!');
      setLoading(false)
      setFormData({
        name: "",
        email: "",
        category: "",
        message: "",
        acknowledge: false,
        terms: false,
      })
    } else {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      alert('Failed to send email');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('An error occurred');
  }

  };
  

  return (
    <Container>
      <LoadingOverlay open={loading} message="Loading, please wait..."/>
      <Typography
        variant="body1"
        fontSize={"1.3rem"}
        fontFamily={"Poppins"}
        gutterBottom
        style={{ color: "#2e2e2e" }}
      >
        Have a question or need assistance? Our friendly, pet-loving team will
        do their very best to help you out!
      </Typography>
      <Divider />

      <Grid container spacing={5} sx={{ padding: { lg: 5, md: 5 } }}>
        <Grid item xs={12} md={6} sm={6} lg={6}>
          <TextField
            fullWidth
            label="Your Name"
            variant="outlined"
            margin="normal"
            name="name"
            value={formData.name}
              onChange={handleChange}
              required
          />
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
             name="email"
             error={error}
             helperText={helperText}
            value={formData.email}
              onChange={handleChange}
              required
          />
          <FormControl component="fieldset" margin="normal">
            <RadioGroup  value={formData.category}
                onChange={handleChange} name="category">
              <FormControlLabel
                sx={{ alignItems: "flex-start", m: 0.1 }}
                value="compliment"
                control={<Radio sx={{ padding: 0, paddingRight: 1 }} />}
                label="Compliment/Complaint
"
              />
              <FormControlLabel
                value="problem"
                sx={{ alignItems: "flex-start", m: 0.1 }}
                control={<Radio sx={{ padding: 0, paddingRight: 1 }} />}
                label="Order Issue"
              />
              <FormControlLabel
                value="dietary"
                sx={{ alignItems: "flex-start", m: 0.1 }}
                control={<Radio sx={{ padding: 0, paddingRight: 1 }} />}
                label="Dietary Enquiry"
              />
              <FormControlLabel
                value="other"
                sx={{ alignItems: "flex-start", m: 0.1 }}
                control={<Radio sx={{ padding: 0, paddingRight: 1 }} />}
                label="Others"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            fullWidth
            label="Message"
            variant="outlined"
            margin="normal"
            multiline
            name="message"
            rows={4}
            value={formData.message}
              onChange={handleChange}
              required
          />
          <FormControl component="fieldset" margin="normal">
            <FormControlLabel
              sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              control={<Checkbox  name="acknowledge" checked={formData.acknowledge}
              onChange={handleChange} sx={{ marginTop: "-10px" }} />}
              label="I acknowledge that, although The Vet Store and its employees strive to offer accurate nutritional and health care advice, this does not substitute or diminish any professional advice provided by a veterinarian. It is my responsibility to consult a veterinarian for any health concerns regarding my pet."
            />
            {/* <FormControlLabel
              sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              control={<Checkbox sx={{ marginTop: "-10px" }} />}
              label="I understand that, whilst every effort is given by The Vet Store and its employees to provide sound nutritional and health care advice, this does in no way replace or invalidate any professional advice given by a veterinary practitioner."
            /> */}
            <FormControlLabel
              sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              control={<Checkbox name="terms" checked={formData.terms}
              onChange={handleChange} sx={{ marginTop: "-10px" }} />}
              label="I have read and understood the terms and conditions."
            />
          </FormControl>
          {/* // change to orange color  */}
          {/* {bullet points for phone and email } */}
          {/* {font size reduced by 2px out stores} */}
          {/* {desktop start abousts  at last conatct us } */}
          {/* color="#ff6501" */}
          <Button
          
           onClick={handleSubmit}
            variant="contained"
            sx={{
              background: "#ff6501",
              "&:hover": {
                background: "#ff6501",
              },
            }}
          >
            Submit
          </Button>
        </Grid>

        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Box>
            <Typography variant="body1" fontFamily={"Poppins"}>
              <strong>Phone:</strong>
              <br />
              <Box
                sx={{ display: { xs: "flex" } }}
                display={"flex"}
                alignItems={"center"}
              >
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ fontSize: "15px", marginRight: 5 }}
                />{" "}
                Capricorn: +27 21 709 0064
                <br />
              </Box>
              <Box
                sx={{ display: { xs: "flex" } }}
                display={"flex"}
                alignItems={"center"}
              >
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ fontSize: "15px", marginRight: 5 }}
                />{" "}
                Sanbury: +27 21 493 3272
                <br />
              </Box>
              <Box
                sx={{ display: { xs: "flex" } }}
                display={"flex"}
                alignItems={"center"}
              >
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ fontSize: "15px", marginRight: 5 }}
                />{" "}
                Somerset West: +27 21 493 5029
                <br />
              </Box>
              <Box
                sx={{ display: { xs: "flex" } }}
                display={"flex"}
                alignItems={"center"}
              >
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ fontSize: "15px", marginRight: 5 }}
                />{" "}
                Vangate: +27 21 638 2055
                <br />
              </Box>
              <Box
                sx={{ display: { xs: "flex" } }}
                display={"flex"}
                alignItems={"center"}
              >
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ fontSize: "15px", marginRight: 5 }}
                />{" "}
                Vangate North: +27 21 633 2562
              </Box>
            </Typography>
            <Typography fontFamily={"Poppins"} variant="body1" marginTop={2}>
              <strong>Email:</strong>
              <br />
              <Box
                sx={{ display: { xs: "flex" } }}
                display={"flex"}
                alignItems={"center"}
              >
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ fontSize: "15px", marginRight: 5 }}
                />
                info.thevetstore@gmail.com
              </Box>
            </Typography>
            {/* <Typography fontFamily={"Poppins"} variant="body1" marginTop={2}>
              <strong>Staff member:</strong>
              <br />
              <Box
                sx={{ display: { xs: "flex" } }}
                display={"flex"}
                alignItems={"center"}
              >
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ fontSize: "15px", marginRight: 5 }}
                />
                <Link to={"/ourteams"} style={{textDecoration:"none",color:"#000"}}>
                Samantha (Front shop sales)
                </Link>
              </Box>
              <Box
                sx={{ display: { xs: "flex" } }}
                display={"flex"}
                alignItems={"center"}
              >
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ fontSize: "15px", marginRight: 5 }}
                />
                <Link to={"/ourteams"} style={{textDecoration:"none",color:"#000"}}>
                Meagan (Front shop sales)
                </Link>
              </Box>
              <Box
                sx={{ display: { xs: "flex" } }}
                display={"flex"}
                alignItems={"center"}
              >
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ fontSize: "15px", marginRight: 5 }}
                />
                <Link to={"/ourteams"} style={{textDecoration:"none",color:"#000"}}>
                Yolande (Front shop sales)
                </Link>
              </Box>
            </Typography> */}
            {/* <Typography variant="body1" marginTop={2} fontFamily={"Poppins"}>
              <strong>Follow us on Facebook:</strong>
              <br />
              <a
                href="https://www.facebook.com/the.vet.store.online.shop"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Vet Store Online
              </a>
            </Typography> */}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactUsForm;

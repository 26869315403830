import { Button, Grid } from "@mui/material";
import FrameComponent1 from "../components/FrameComponent1";
import GroupComponent1 from "../components/GroupComponent1";
import BrandContainer from "../components/BrandContainer";
import NewArrivalsList from "../components/NewArrivalsList";
import YoungsFavourites from "../components/YoungsFavourites";
import Community from "../components/Community";
import GroupComponent2 from "../components/GroupComponent2";
import styles from "./ShoppingApp.module.css";
import PetSFoodCategory from "../components/PetSFoodCategory";
import Layout from "../components/Layout";
import SpecialSale from "../components/SpecialSale";
import { useEffect } from "react";
import { getLandingpageinfo } from "../helper/utils/landingpageapi";

const ShoppingApp = () => {

  return (
    <Layout>
      <Grid p={1}>
        <Grid>
          <GroupComponent1 />
        </Grid>
        <Grid
          p={0}
          style={{
            backgroundColor: "#ff6501",
          }}
        >
          <BrandContainer />
        </Grid>{" "}
        <Grid>
          <PetSFoodCategory />
        </Grid>
        <Grid>
          <NewArrivalsList />
        </Grid>
        <Grid id="targetElement">
          <SpecialSale />
        </Grid>
        <Grid>
          <YoungsFavourites />
        </Grid>
        <Grid>
          <Community />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ShoppingApp;

import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";


const Mapcomponent = ({marker}) => {
  const [map, setMap] = React.useState(null);

  const containerStyle = {
    width: "100%",
    height: "200px",
  };

  const center = {
    lat: -34.07220354773511,
    lng: 18.50174135251041,
  };

  const onLoad = React.useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  return (
    <LoadScript googleMapsApiKey="AIzaSyBhAbFGb3Y99hMoltzcdFRCmPJq04N-sqs">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={marker.location}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
         <Marker position={marker.location} />
       
      </GoogleMap>
    </LoadScript>
  );
};

export default Mapcomponent;


{/* <Mapcomponent
  marker={{
    location: {
      lat: -34.07220354773511,
      lng: 18.50174135251041,
    },
  }}
/> */}


{/* <Button
          sx={{
            color: "#ff6501",
            borderColor: "#ff6501",
            background:"#fff",
            "&:hover": {
              backgroundColor: "#ff6501",
              borderColor: "#ff6501",
              color: "white",
            },
            position: "absolute",
            top: {xs:"97%", sm: "75%",md: "75%",lg:"75%"},
            left:{xs:"47%", sm: "75%",md: "75%",lg:"75%"},
          }}
          variant="outlined"
          onClick={() => window.scrollTo({
            top: window.innerHeight*4.3, 
            behavior: "smooth" 
          })}
        >
          promotions
        </Button> */}
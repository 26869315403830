import axios from "axios";
import axiosInstance, { PRIVACY_POLICY, TERMS_OF_SERVICE } from "../api/axiosconfig"
import { LANDING_PAGE } from "../apiEndpoints"

export const getLandingpageinfo = async () => {
  try {
    const response = await axiosInstance.get(LANDING_PAGE.PROMOTION);
    if (response === false) return;
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getLandingpagespecialsale = async () => {
  try {
    const response = await axiosInstance.get(LANDING_PAGE.SPECIAL_SALE);
    if (response === false) return;
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getcontactpageinfo = async () => {
  try {
    const response = await axiosInstance.get(LANDING_PAGE.CONATCT);
    if (response === false) return;
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getcontactUspageinfo = async () => {
  try {
    const response = await axiosInstance.get(LANDING_PAGE.CONATCTUS);
    if (response === false) return;
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAboutUspageinfo = async () => {
  try {
    const response = await axiosInstance.get(LANDING_PAGE.ABOUTUS);
    if (response === false) return;
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getCommunitypageinfo = async () => {
  try {
    const response = await axiosInstance.get(LANDING_PAGE.PROMOTEXTCONTENT);
    if (response === false) return;
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const gettermsandcondistion = async () => {
  try {
    const response = await axiosInstance.get(LANDING_PAGE.TERMSANDCONDN);
    if (response === false) return;
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getPrivacypolicy = async () => {
  try {
    const response = await axiosInstance.get(PRIVACY_POLICY);
    if (response === false) return;
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
import { Grid, Box, Button, Typography, CircularProgress } from "@mui/material";
import GroupComponent from "./GroupComponent";
import FrameComponent from "./FrameComponent";
import { useContext } from "react";
import { MyContext } from "../Context/websiteinfocontext";
import puppycare from "../assets/brandlogo/puppycare.png"
import kittencare from "../assets/brandlogo/kittencare.png"

const YoungsFavourites = ({ className = "", ref }) => {
  const { state } = useContext(MyContext);
  return (
    <Box
      ref={ref}
      pt={2}
      pb={5}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        justifyContent: "center",
        boxSizing: "border-box",
        textAlign: "left",
        fontSize: "2rem",
        color: "var(--color-black)",
        fontFamily: "var(--font-poppins)",
        maxWidth: "100%",
        "@media screen and (max-width: 1850px)": {
          flexWrap: "wrap",
        },
        "@media screen and (max-width: 925px)": {
          gap: "var(--gap-32xl)",
        },
        "@media screen and (max-width: 450px)": {
          gap: "var(--gap-6xl)",
        },
      }}
      className={className}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          justifyContent: "flex-start",
          // gap: "var(--gap-32xl)",
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%", // Use percentage for responsive width
            maxWidth: "35.688rem", // Set max width to keep it within bounds
            height: "auto", // Auto height to maintain aspect ratio
            aspectRatio: "25.688 / 3.619", // Maintain aspect ratio for responsiveness
          }}
        >
          <img
            style={{
              position: "absolute",
              top: "15%", // Use percentage for positioning
              left: "60%", // Use percentage for positioning
              width: "32%", // Use percentage for sizing
              height: "auto", // Auto height to maintain aspect ratio
            }}
            loading="lazy"
            alt=""
            src="/vector-8.svg"
          />
          <Box
            sx={{
              margin: 0,
              position: "absolute",
              top: 0,
              left: 0,
              fontSize: "inherit",
              letterSpacing: "0.06em",
              lineHeight: "3.125rem",
              fontWeight: 900,
              fontFamily: "inherit",
              display: "inline-block",
              width: "100%",
              height: "100%",
              zIndex: 1,
              "@media screen and (max-width: 925px)": {
                fontSize: "var(--font-size-19xl)",
                lineHeight: "2.5rem",
              },
              "@media screen and (max-width: 450px)": {
                fontSize: "var(--font-size-10xl)",
                lineHeight: "1.875rem",
              },
            }}
          >
            Puppy and Kitten care
          </Box>
        </Box>
        {/* <Grid container spacing={0}>
    <Grid item xs={12} sm={6}>
      <GroupComponent
        snacksImage="/rectangle-50@2x.png"
        popularSnacks="Popular Snacks"
        arrow4="/arrow-1.svg"
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <GroupComponent
        snacksImage="/rectangle-49@2x.png"
        popularSnacks="Chews"
        arrow4="/arrow-2.svg"
      />
    </Grid>
  </Grid> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <FrameComponent
              productImages={puppycare}
              freshFlavors="Puppy care"
              arrow1="/arrow-2.svg"
              url={
                "https://shop.thevetstore.co.za/product/178"
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FrameComponent
              productImages={kittencare}
              freshFlavors="Kitten care"
              arrow1="/arrow-2.svg"
              url={
                "https://shop.thevetstore.co.za/product/177"
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            textAlign={"center"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#ff6501",
                borderColor: "#ff6501",
                "&:hover": {
                  backgroundColor: "#ff6501",
                  borderColor: "#ff6501",
                  color: "white",
                },
              }}
              onClick={() => {
                window.open("https://digitail.com/pet-parent-app/ ", "_blank");
              }}
            >
              Book Vet Consult
            </Button>
          </Grid>
        </Grid>
        {Object.keys(state).length <= 0 || state === undefined ? (
          <Grid
            width={"100%"}
            mt={2}
            display={"flex"}
            justifyContent={"center"}
          >
            <CircularProgress color="primary" style={{ color: "#ff6501" }} />
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              width: "100%",
              border: "1px solid #f4f6f5",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={2.5}>
                <img
                  src={state.shop_image1?state.shop_image1:"https://docketell-file.s3.amazonaws.com/Shop_logoes/3adf9da6620f4970869b4149b066c1b8_Screenshot_2024-07-24_132104.png"}
                  alt={state.shop_image1}
                  loading="lazy"
                  // src="Screenshot 2024-07-24 132118.png"
                  // alt="Screenshot 2024-07-24 132118.png"
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid item xs={7} textAlign="center">
                <Typography
                  sx={{
                    fontSize: {
                      xs: "0.9rem",
                      sm: "1.6rem",
                      md: "1.8rem",
                      lg: "2rem",
                    },
                    fontFamily: "poppins",
                    color: "#ed1b24",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  {state.shop_text?state.shop_text  :   "TAILORED NUTRITION THAT HELPS DOGS AND CATS LIVE THEIR HEALTHIEST LIVES."}
                  {/* TAILORED NUTRITION THAT HELPS DOGS AND CATS LIVE THEIR
                  HEALTHIEST LIVES. */}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "0.7rem",
                    fontWeight: "400",
                    fontFamily: "poppins",
                    width: "8rem",
                    background: "#ed1b24",
                    borderRadius: "10px",
                    mt: 2,
                    "&:hover": {
                      background: "#ed1b24",
                    },
                  }}
                  onClick={() =>
                    window.open("https://shop.thevetstore.co.za/", {
                      target: "_blank",
                    })
                  }
                >
                  Shop Now
                </Button>
              </Grid>
              <Grid item xs={2.5}>
                <img
                  src={state.shop_image2?state.shop_image2 :"https://docketell-file.s3.amazonaws.com/Shop_imagees/9a623aec2a5549f18738824bf1e5df47_Screenshot_2024-07-24_132118.png"}
                  alt={state.shop_image1}
                  // src={"/Screenshot 2024-07-24 132104.png"}
                  // alt={"/Screenshot 2024-07-24 132104.png"}
                  loading="lazy"
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {/* <img
      src="/royalcaninhomepage1-1@2x.png"
      alt=""
      loading="lazy"
      style={{ width: "100%", height: "auto" }} // Ensure image scales proportionally
    /> */}
      </Box>
    </Box>
  );
};

export default YoungsFavourites;

import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { getAboutUspageinfo } from "../helper/utils/landingpageapi";
import {
  Grid,
  CircularProgress,
  Typography,
  Box,
  Divider,
} from "@mui/material";

function About() {
  const [contactus, setContactUs] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const paragraphs = containerRef.current.getElementsByTagName("span");
      for (let p of paragraphs) {
        p.style.fontSize = "1.2rem";
        p.style.fontFamily = "Poppins, sans-serif";
        p.style.color = "#445b4b";
      }
      const paragraph = containerRef.current.getElementsByTagName("p");
      for (let p of paragraph) {
        p.style.marginBottom = "15px";
      }
    }
  }, [loading]);

  useEffect(() => {
    getAboutUspageinfo()
      .then((res) => {
        setContactUs(res?.about);
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to fetch the data.");
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress color="primary" style={{ color: "#ff6501" }} />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid justifyContent="center" sx={{ padding: 0 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={11}
          sx={{
            position: "relative",
            minHeight: "45vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            overflow: "hidden",
          }}
        >
          <img
            src="https://6dc22ea86c.cbaul-cdnwnd.com/1feadb516279ca2daa0f913f1148a52d/200000054-1583c1583e/child.webp?ph=6dc22ea86c"
            alt="Background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Typography
            variant="h3"
            component="h1"
            sx={{
              // fontSize: 25,
              fontFamily: "Poppins",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0)", // Optional: adds a slight background to improve text readability
              padding: 1,
              borderRadius: 1,
              zIndex: 1,
            }}
          >
            About Us
          </Typography>
        </Grid>
        <Grid container p={2}>
          <Typography
            variant="body1"
            sx={{
              fontSize: 18,
              fontFamily: "Poppins",
              color: "#2c2c2c",
              // backgroundColor: "rgba(0, 0, 0, 0.5)",
              padding: 2,
              borderRadius: 1,
              zIndex: 1,
            }}
          >
            <Typography style={{ fontSize: "25px", fontFamily: "Poppins" }}>
              Our Commitment to You
            </Typography>
            <br />
            We take great pride in the attention and dedication we provide to
            each client. We understand how significant your pet is to you—just
            like a cherished family member.
            <br />
            <br />
            At The Vet Store, we consider ourselves a family, and our team is
            continually learning new ways to enhance the happiness and health of
            your furry family member.
            <br />
            <br />
            <Typography style={{ fontSize: "25px", fontFamily: "Poppins" }}>
              What Sets Us Apart
            </Typography>
            <br />
            Every staff member is a Certified Veterinary Nutritional Adviser,
            fully qualified to offer expert nutritional advice for your pet’s
            well-being. Through ongoing education, we ensure that our team
            provides exceptional, personalized service.
            <br />
            <br />
            <Typography style={{ fontSize: "25px", fontFamily: "Poppins" }}>
              100% Vet-Owned Stores
            </Typography>
            <br />
            We are proud to be 100% vet-owned, ensuring compliance with all
            South African Veterinary Law regulations. Being vet-owned also
            grants us access to products that may not be available at other "vet
            shops" or online stores.
            <br />
            {/* <br />
            Being 100% vet owned, enables us to have access to products that
            some "vet shops" and online stores may not be permitted to stock. */}
          </Typography>
        </Grid>
        <Divider />
        <br />
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "35px",
              fontFamily: "Poppins",
              textAlign: "center",
            }}
          >
            Your Shopping Experience
          </Typography>
        </Grid>
        <Grid sx={{ display: { sm: "flex", lg: "flex" } }} p={4}>
          <Grid
            xs={12}
            md={8}
            sm={8}
            lg={8}
            sx={{ width: { md: "30%", sm: "30%", lg: "30%" } }}
          >
            <img
              src="https://6dc22ea86c.cbaul-cdnwnd.com/1feadb516279ca2daa0f913f1148a52d/200000060-f00fff0101/vangate%20shopper.webp?ph=6dc22ea86c"
              alt="Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: -1,
              }}
            />
            {/* </div> */}
          </Grid>
          <Grid xs={12} md={4} sm={4} lg={4} p={3} style={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: 18,
                fontFamily: "Poppins",
                color: "#2c2c2c",
                // backgroundColor: "rgba(0, 0, 0, 0.5)",
                padding: 2,
                borderRadius: 1,
                zIndex: 1,
              }}
            >
              <br />
              Since opening in 2008, The Vet Store has steadily expanded to five
              thriving locations, each staffed by dedicated professionals who
              provide excellent advice on caring for your beloved pets.
              <br />
              <br />
              Our team forms such close relationships with clients that it feels
              like we’re all part of one big family.
              <br />
              <br />
              When you visit The Vet Store, you are welcomed with a warm smile
              and a comfortable environment perfect for browsing.
              <br />
              <br />
              All of our staff members are qualified Veterinary Nutritional
              Advisors, equipped to address any questions you might have about
              your pet's dietary health.
              <br />
              <br />
              Through ongoing education and veterinary-organized workshops, our
              team is trained in the sale and advice of over-the-counter,
              non-prescription medications and foods. Whether it’s simple
              deworming or recommending remedies for common issues like
              intestinal upsets, we’re here to help.
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <br />
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "35px",
              fontFamily: "Poppins",
              textAlign: "center",
            }}
          >
            Caring for the Community
          </Typography>
        </Grid>
        <Grid sx={{ display: { sm: "flex", lg: "flex" } }} p={4}>
          <Grid
            xs={12}
            md={8}
            sm={8}
            lg={8}
            sx={{ width: { md: "30%", sm: "30%", lg: "30%" } }}
          >
            <img
              src="https://6dc22ea86c.cbaul-cdnwnd.com/1feadb516279ca2daa0f913f1148a52d/200000061-0514605148/DSCF0032-2-600x800.webp?ph=6dc22ea86c"
              alt="Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: -1,
              }}
            />
            {/* </div> */}
          </Grid>
          <Grid xs={12} md={4} sm={4} lg={4} p={3} style={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: 18,
                fontFamily: "Poppins",
                color: "#2c2c2c",
                // backgroundColor: "rgba(0, 0, 0, 0.5)",
                padding: 0,
                borderRadius: 1,
                zIndex: 1,
              }}
            >
              <Typography
                style={{
                  fontSize: "25px",
                  fontFamily: "Poppins",
                  textAlign: "left",
                }}
              >
                Work4You
              </Typography>
              <br />
              Work4You is a training and support program designed for young
              adults with intellectual disabilities to thrive in the open labor
              market.
              <br />
              <br />
              We are proud to stock Wagalot biscuits, which are handmade by the
              Work4You team.
              <br />
              <br />
              Wagalot Biscuits are not only a delicious and nutritious treat for
              dogs, but they also provide valuable training and skills for
              participants, helping them gain experience and earn an income.
              <br />
              <br />
              Check them out on Instagram:
              <br />
              <a
                style={{ color: "red", marginLeft: "5px", fontSize: "40px" }}
                href="https://www.instagram.com/work4youza_org"
                target="_blank"
              >
                <i className="bi bi-instagram"></i>
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default About;
// import React, { useEffect, useRef, useState } from "react";
// import Layout from "../components/Layout";
// import { getAboutUspageinfo } from "../helper/utils/landingpageapi";
// import {
//   Grid,
//   CircularProgress,
//   Typography,
//   Box,
//   Divider,
// } from "@mui/material";

// function About() {
//   const [contactus, setContactUs] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState("");

//   const containerRef = useRef(null);

//   useEffect(() => {
//     if (containerRef.current) {
//       const paragraphs = containerRef.current.getElementsByTagName("span");
//       for (let p of paragraphs) {
//         p.style.fontSize = "1.2rem";
//         p.style.fontFamily = "Poppins, sans-serif";
//         p.style.color = "#445b4b";
//       }
//       const paragraph = containerRef.current.getElementsByTagName("p");
//       for (let p of paragraph) {
//         p.style.marginBottom = "15px";
//       }
//     }
//   }, [loading]);

//   useEffect(() => {
//     getAboutUspageinfo()
//       .then((res) => {
//         setContactUs(res?.about);
//         setLoading(false);
//       })
//       .catch((err) => {
//         setError("Failed to fetch the data.");
//         setLoading(false);
//       });
//   }, []);

//   if (loading) {
//     return (
//       <Layout>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             minHeight: "50vh",
//           }}
//         >
//          <CircularProgress color="primary" style={{ color: "#ff6501" }} />
//         </Box>
//       </Layout>
//     );
//   }

//   if (error) {
//     return (
//       <Layout>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             minHeight: "50vh",
//           }}
//         >
//           <Typography variant="h6" color="error">
//             {error}
//           </Typography>
//         </Box>
//       </Layout>
//     );
//   }

//   return (
//     <Layout>
//       <Grid justifyContent="center" sx={{ padding: 0 }}>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={10}
//           lg={11}
//           sx={{
//             position: "relative",
//             minHeight: "45vh",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             padding: 2,
//             backgroundColor: "rgba(0, 0, 0, 0.5)",
//             overflow: "hidden",
//           }}
//         >
//           <img
//             src="https://6dc22ea86c.cbaul-cdnwnd.com/1feadb516279ca2daa0f913f1148a52d/200000054-1583c1583e/child.webp?ph=6dc22ea86c"
//             alt="Background"
//             style={{
//               position: "absolute",
//               top: 0,
//               left: 0,
//               width: "100%",
//               height: "100%",
//               objectFit: "cover",
//               zIndex: -1,
//             }}
//           />
//           <Typography
//             variant="h3"
//             component="h1"
//             sx={{
//               // fontSize: 25,
//               fontFamily: "Poppins",
//               color: "white",
//               backgroundColor: "rgba(0, 0, 0, 0)", // Optional: adds a slight background to improve text readability
//               padding: 1,
//               borderRadius: 1,
//               zIndex: 1,
//             }}
//           >
//             About Us
//           </Typography>
//         </Grid>
//         <Grid container p={2}>
//           <Typography
//             variant="body1"
//             sx={{
//               fontSize: 18,
//               fontFamily: "Poppins",
//               color: "#2c2c2c",
//               // backgroundColor: "rgba(0, 0, 0, 0.5)",
//               padding: 2,
//               borderRadius: 1,
//               zIndex: 1,
//             }}
//           >
//             <Typography style={{ fontSize: "25px", fontFamily: "Poppins" }}>
//               OUR PROMISE TO YOU
//             </Typography>
//             <br />
//             We pride ourselves in the attention and dedication we give to each
//             and every client. We know how important your pet is to you.....just
//             like a member of the family.
//             <br />
//             <br />
//             At The Vet Store, we are all like family and our team members are
//             continually learning new ways to help make your furry, family
//             friend's life happier and healthier.
//             <br />
//             <br />
//             <Typography style={{ fontSize: "25px", fontFamily: "Poppins" }}>
//               WHAT SEPARATES US FROM THE REST?
//             </Typography>
//             <br />
//             Every staff member is a Certified Veterinary Nutritional Adviser and
//             all are qualified to give nutritional advice for the better health
//             of your pet. By constantly refreshing and expanding their knowledge
//             through continued education, you can be assured of great,
//             personalized service from the entire team.
//             <br />
//             <br />
//             <Typography style={{ fontSize: "25px", fontFamily: "Poppins" }}>
//               100% VET OWNED STORES
//             </Typography>
//             <br />
//             We are very proud to say that our outlets are 100% vet owned so you
//             can rest assured that we operate within all the legal parameters as
//             laid out by South African Veterinary Law.
//             <br />
//             <br />
//             Being 100% vet owned, enables us to have access to products that
//             some "vet shops" and online stores may not be permitted to stock.
//           </Typography>
//         </Grid>
//         <Divider />
//         <br />
//         <Grid
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Typography
//             style={{
//               fontSize: "35px",
//               fontFamily: "Poppins",
//               textAlign: "center",
//             }}
//           >
//             Your shopping Experience....
//           </Typography>
//         </Grid>
//         <Grid sx={{ display: {sm:"flex",lg:"flex"}, }} p={4}>
//           <Grid xs={12} md={8} sm={8} lg={8} sx={{ width: {md:"30%",sm:"30%",lg:"30%"} }}>
//             <img
//               src="https://6dc22ea86c.cbaul-cdnwnd.com/1feadb516279ca2daa0f913f1148a52d/200000060-f00fff0101/vangate%20shopper.webp?ph=6dc22ea86c"
//               alt="Background"
//               style={{
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//                 zIndex: -1,
//               }}
//             />
//             {/* </div> */}
//           </Grid>
//           <Grid xs={12} md={4} sm={4} lg={4} p={3} style={{ width: "100%" }}>
//             <Typography
//               variant="body1"
//               sx={{
//                 fontSize: 18,
//                 fontFamily: "Poppins",
//                 color: "#2c2c2c",
//                 // backgroundColor: "rgba(0, 0, 0, 0.5)",
//                 padding: 2,
//                 borderRadius: 1,
//                 zIndex: 1,
//               }}
//             >
//               <br />
//               The Vet Store first opened its doors in 2008, and has grown from
//               strength to strength, boasting 5 flourishing outlets with
//               dedicated staff who give great advice on the best way to care for
//               your fur-kids.
//               <br />
//               <br />
//               Our team have such a good relationship with their clients that
//               they often say it feels like they are family.
//               <br />
//               <br />
//               Visitors to The Vet Store, are greeted with a warm smile and a
//               cosy shopping environment in which to browse.
//               <br />
//               <br />
//               All of our staff have qualified as Veterinary Nutritional Advisors
//               and are more than capable of answering any of the nutritional
//               questions that you may have regarding your pet's dietary health.
//               <br />
//               <br />
//               Through continued education and veterinary organized workshops,
//               all of our staff are trained in the selling and giving advice of
//               our over-the-counter, non-prescription medication and food. From
//               simple deworming to being able to recommend remedies for
//               intestinal upsets, a condition that often occurs in our family's
//               pets, we've got you covered.
//             </Typography>
//           </Grid>
//         </Grid>
//         <Divider />
//         <br />
//         <Grid
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Typography
//             style={{
//               fontSize: "35px",
//               fontFamily: "Poppins",
//               textAlign: "center",
//             }}
//           >
//             Caring for the community....
//           </Typography>
//         </Grid>
//         <Grid sx={{ display: {sm:"flex",lg:"flex"}, }}  p={4}>
//           <Grid xs={12} md={8} sm={8} lg={8} sx={{ width: {md:"30%",sm:"30%",lg:"30%"} }}>
//             <img
//               src="https://6dc22ea86c.cbaul-cdnwnd.com/1feadb516279ca2daa0f913f1148a52d/200000061-0514605148/DSCF0032-2-600x800.webp?ph=6dc22ea86c"
//               alt="Background"
//               style={{
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//                 zIndex: -1,
//               }}
//             />
//             {/* </div> */}
//           </Grid>
//           <Grid xs={12} md={4} sm={4} lg={4} p={3} style={{ width: "100%" }}>
//             <Typography
//               variant="body1"
//               sx={{
//                 fontSize: 18,
//                 fontFamily: "Poppins",
//                 color: "#2c2c2c",
//                 // backgroundColor: "rgba(0, 0, 0, 0.5)",
//                 padding: 0,
//                 borderRadius: 1,
//                 zIndex: 1,
//               }}
//             >
//               <Typography
//                 style={{
//                   fontSize: "25px",
//                   fontFamily: "Poppins",
//                   textAlign: "left",
//                 }}
//               >
//                 Work4You
//               </Typography>
//               <br />
//               Work4You is a training & support program for young adults with
//               intellectual disabilities in the open labour market.
//               <br />
//               <br />
//               We are proud stockists of Wagalot biscuits, hand made by the
//               Work4You team.
//               <br />
//               <br />
//               Wagalots Biscuits are a tasty, nutritious, handmade dog treat made
//               by members to generate an income; as well as offering the valuable
//               training and skills needed for maintaining a job in the open
//               labour market.
//               <br />
//               <br />
//               Check them out on Instagram:
//               <br />
//               <a
//                 style={{ color: "red", marginLeft: "5px", fontSize: "40px" }}
//                 href="https://www.instagram.com/work4youza_org"
//                 target="_blank"
//               >
//                 <i className="bi bi-instagram"></i>
//               </a>
//             </Typography>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Layout>
//   );
// }

// export default About;

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import FrameComponent from "./FrameComponent";
import Treats from "../assets/brandlogo/treats.png" 
import DogFood from "../assets/brandlogo/catfood.png" 
import CatFood from "../assets/brandlogo/dogfood.png" 

const NewArrivalsList = ({ className = "" }) => {
  return (
    <Box
      pb={3}
      sx={{
        paddingTop:{xs:"10px" , sm:"10px", md:"5px",lg:"10px"},
        // display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        justifyContent: "center",
        boxSizing: "border-box",
        textAlign: "left",
        fontSize: "var(--font-size-29xl)",
        color: "var(--color-black)",
        fontFamily: "var(--font-poppins)",
        maxWidth: "100%",
        "@media screen and (max-width: 1850px)": {
          flexWrap: "wrap",
        },
        "@media screen and (max-width: 925px)": {
          gap: "var(--gap-32xl)",
        },
        "@media screen and (max-width: 450px)": {
          gap: "var(--gap-6xl)",
        },
      }}
      className={className}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          justifyContent: "flex-start",
          // gap: "var(--gap-32xl)",
          
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%", // Use percentage for responsive width
            maxWidth: "25.688rem", // Set max width to keep it within bounds
            height: "auto", // Auto height to maintain aspect ratio
            aspectRatio: "25.688 / 3.619", // Maintain aspect ratio for responsiveness
          }}
        >
          <img
            style={{
              position: "absolute",
              top: "20%", // Use percentage for positioning
              left: "30%", // Use percentage for positioning
              width: "45%", // Use percentage for sizing
              height: "auto", // Auto height to maintain aspect ratio
            }}
            loading="lazy"
            alt=""
            src="/vector-8.svg"
          />
          <Box
            sx={{
              margin: 0,
              position: "absolute",
              top: 0,
              left: 0,
              fontSize: "2rem",
              letterSpacing: "0.06em",
              lineHeight: "3.125rem",
              fontWeight: 900,
              fontFamily: "poppins",
              display: "inline-block",
              width: "100%", // Use percentage to match container width
              height: "100%", // Use percentage to match container height
              zIndex: 1,
              "@media screen and (max-width: 925px)": {
                fontSize: "var(--font-size-19xl)",
                lineHeight: "2rem",
              },
              "@media screen and (max-width: 450px)": {
                fontSize: "var(--font-size-10xl)",
                lineHeight: "1.575rem",
              },
            }}
          >
            Top Selling
          </Box>
        </Box>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6} md={4}>
            <FrameComponent
              productImages={Treats}
              freshFlavors="Treats"
              arrow1="/arrow-1.svg"
              url={"https://shop.thevetstore.co.za/product/120"}
              />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FrameComponent
              productImages={CatFood}
              freshFlavors="Dog Food"
              arrow1="/arrow-2.svg"
              url={"https://shop.thevetstore.co.za/product/81"}
              />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FrameComponent
              productImages={DogFood}
              freshFlavors="Cat Food"
              arrow1="/arrow-2.svg"
              url={"https://shop.thevetstore.co.za/product/153"}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

NewArrivalsList.propTypes = {
  className: PropTypes.string,
};

export default NewArrivalsList;

// import { useMemo } from "react";
// import PropTypes from "prop-types";
// import {
//   Card,
//   CardContent,
//   Typography,
//   CardMedia,
//   Box,
//   IconButton,
//   Button,
// } from "@mui/material";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// const Teams = ({
//   productImages,
//   freshFlavors,
//   arrow1,
//   propWidth,
//   propGap,
//   url,
// }) => {
//   const frameDivStyle = useMemo(
//     () => ({
//       width: propWidth,
//       gap: propGap,
//     }),
//     [propWidth, propGap]
//   );

//   return (
//     <Card
//       sx={{
//         ...frameDivStyle,
//         padding: 0,
//         background: "#f4f6f5",
//         borderRadius: "10px",
//         border: "1px solid #f7f7f7",
//         display: "flex",
//         boxShadow: "none",
//         flexDirection: "column",
//         alignItems: "center",
//         width: "100%",
//       }}
//     >
//       <CardMedia
//         component="img"
//         image={productImages}
//         alt=""
//         loading="lazy"
//         sx={{
//           width:"100%",
//           height: "auto",
//           minHeight:"400px",
//           maxHeight:"400px",
//           objectFit: "cover",
//         }}
//       />
//       <CardContent
//         sx={{
//           display: "flex",
//           padding: 1,
//           alignItems: "center",
//           width: "100%",
//           margin: "auto",
//           justifyContent: "space-between",
//         }}
//       >
//         <div onClick={() => window.open(url, { target: "_blank" })}>
//           <Typography
//             variant="body2"
//             sx={{
//               mb: 0.5,
//               fontFamily: "poppins",
//               fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
//               height:"45px",
//               minHeight:"45px",
//               maxHeight:"45px"
//             }}
//           >
//             {freshFlavors}
//           </Typography>
//         </div>
//       </CardContent>
//     </Card>
//   );
// };

// Teams.propTypes = {
//   productImages: PropTypes.string,
//   freshFlavors: PropTypes.string,
//   arrow1: PropTypes.string,
//   propWidth: PropTypes.any,
//   propGap: PropTypes.any,
// };

// export default Teams;

import { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Teams = ({
  productImages,
  freshFlavors,
  freshFlavors1,
  freshFlavors2,
  arrow1,
  propWidth,
  propGap,
  url,
}) => {
  const frameDivStyle = useMemo(
    () => ({
      width: propWidth,
      gap: propGap,
    }),
    [propWidth, propGap]
  );

  return (
    <Card
      sx={{
        ...frameDivStyle,
        padding: 0,
        background: "#f4f6f5",
        borderRadius: "10px",
        border: "1px solid #f7f7f7",
        display: "flex",
        boxShadow: "none",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        position: "relative", // add this
      }}
    >
      <CardMedia
        component="img"
        image={productImages}
        alt=""
        loading="lazy"
        sx={{
          width: "100%",
          height: "auto",
          minHeight: "350px",
          maxHeight: "350px",
          objectFit: "cover",
        }}
      />
      <CardContent
        sx={{
          position: "absolute", // add this
          bottom: 0, // add this
          left: 0, // add this
          width: "100%", // add this
          padding: 1,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // add this
        }}
      >
        <div >
          <Typography
            variant="body2"
            sx={{
              mb: 0.5,
              fontFamily: "poppins",
              fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
              height: "15px",
              minHeight: "15px",
              maxHeight: "15px",
              color: "#fff", // add this
            }}
          >
            {freshFlavors}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 0.5,
              fontFamily: "poppins",
              fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
              height: "15px",
              minHeight: "15px",
              maxHeight: "15px",
              color: "#fff", // add this
            }}
          >
            {freshFlavors1}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
              height: "15px",
              minHeight: "20px",
              maxHeight: "20px",
              color: "#fff", // add this
            }}
          >
            {freshFlavors2}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

Teams.propTypes = {
  productImages: PropTypes.string,
  freshFlavors: PropTypes.string,
  arrow1: PropTypes.string,
  propWidth: PropTypes.any,
  propGap: PropTypes.any,
};

export default Teams;

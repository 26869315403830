import axios from "axios";
// import { getAccessToken } from "utils";
// import { authRouteConstants } from "constants/routeConstants";
import { API_URL } from '../constants'
import { notify } from "../Notify";

export const TERMS_OF_SERVICE = 'https://www.venturestudiocms.com/api/general-pages/91?populate=hero_section.media, description.paragraphs.media,other_content_blocks.media,quick_links.cards.media'
export const PRIVACY_POLICY = 'https://www.venturestudiocms.com/api/general-pages/90?populate=hero_section.media, description.paragraphs.media,other_content_blocks.media,quick_links.cards.media'


const instance = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: "application/json",
        "domain-name": (function () {

            // return window.location.host;
            // return 'nitintestperfect.webbieshop.com'
            
            return 'thevetstore.webbieshop.com'
        })(),
    }
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("error response interceptors", error.response, error.response.status);
    // if (error && error.response && error.response.status === 400) {
    //   window.location.href = authRouteConstants.LOGIN;
    // }
    // return Promise.reject(error);

    if (error && error.response) {
      if (error.response.status === 401) {
        notify("authentication error.", "error");
        window.location.href = "/";
        return false;
      } 
      // else if (error.response.status === 404) {
      //   notify("The requested resource was not found.", "error");
      //   return false;
      // } 
      else if (error.response.status === 504 || error.response.status === 500) {
        console.log("data",error.response.status)
        notify("The server is currently unavailable. Please try again later.", "error");
        return false;
      }
    } else if (error && error.message === 'Network Error') {
      notify("A network error occurred. Please check your internet connection.", "error");
      return false;
    }

    return error
  }
);

export default instance;
import { Button, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./GroupComponent1.module.css";

const GroupComponent1 = ({ className = "", onClick }) => {
  return (
    <>{true?
    <Dogcompos onClick={onClick}/>
 :    <div className={[styles.modelBackgroundParent, className].join(" ")}>
       <div className={styles.modelBackground} />
       <div className={styles.frameChild} />
       <img
        className={styles.incendiaryFantasticallyBeautIcon}
        alt=""
        src="/incendiaryfantasticallybeautifulgirlcoatecofurmovesfunpicturelovelyladypinkclothesremovebgpreview-1@2x.png"
      />
      
      <div className={styles.petFood}>
        <div className={styles.petFoodContent}>
          <div className={styles.petFoodDescription}>
            <div className={styles.petFoodTitle}>
              <div className={styles.titleBackgroundParent}>
                <div className={styles.titleBackground} />
                <h1 className={styles.discoverPremiumPet}>
                  DISCOVER PREMIUM PET FOODS.
                </h1>
              </div>
            </div>
            <div className={styles.fashionDescription}>
              <div className={styles.liveForInfluential}>
                Live for Influential and Innovative fashion!
              </div>
            </div>
            <div className={styles.shopNowButton}>
              <Button
                className={styles.shopNowButtonChild}
                disableElevation
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "20",
                  background: "#000",
                  borderRadius: "0px 0px 0px 0px",
                  "&:hover": { background: "#000" },
                }}
              >
                Shop Now
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.rating}>
          <img className={styles.starOneIcon} alt="" src="/star-one.svg" />
          <div className={styles.otherStars}>
            <img className={styles.starTwoIcon} alt="" src="/star-one.svg" />
          </div>
        </div>
      </div>
      <div className={styles.moreStars}>
        <div className={styles.starThreeParent}>
          <img className={styles.starThreeIcon} alt="" src="/star-one.svg" />
          <div className={styles.starFourWrapper}>
            <img className={styles.starFourIcon} alt="" src="/star-one.svg" />
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};

GroupComponent1.propTypes = {
  className: PropTypes.string,
};

export default GroupComponent1;




const Dogcompos = ({onClick}) => {


  
  console.log("useuse",window.innerHeight);


  return (
    <Grid
      container
      lg={12}
      md={12}
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
        alignItems: { xs: "baseline", md: "center" },
        background: "#F4F6F5",
        borderRadius: "10px",
        overflow: "hidden",
        p: 1, // Reduced padding
      }}
      mb={2}
      mt={2}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "12px", // Reduced gap
          padding: "12px", // Reduced padding
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              xs: "2rem", // Reduced font size
              sm: "2.5rem",
              md: "2.5rem",
              lg: "3rem",
            },
            fontWeight: "900",
            fontFamily: "Poppins, sans-serif",
          }}
        >
         VET RECOMMENDED
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              xs: "2rem", // Reduced font size
              sm: "2.5rem",
              md: "2.5rem",
              lg: "3rem",
            },
            background: "#ff6501",
            color: "#fff",
            fontWeight: "900",
            fontFamily: "Poppins",
            transform: "rotate(-3deg)",
            display: "inline-block",
          }}
        >
          PET FOODS.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: {
              xs: "0.875rem", // Reduced font size
              sm: "1rem",
              md: "1.25rem",
              lg: "1.5rem",
            },
            fontFamily: "Poppins, sans-serif",
          }}
        >
          Affordable prices | Faster delivery
        </Typography>
        <Button
          sx={{
            fontSize: {
              xs: "0.875rem", // Reduced font size
              sm: "1rem",
              md: "1.25rem",
              lg: "1.35rem",
            },
            padding: "3.5px 7px", // Reduced padding
            borderRadius: "10px", // Adjusted border radius
            textTransform: "none",
            backgroundColor: "#000",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#333",
            },
          }}
          onClick={() =>
            window.open("https://shop.thevetstore.co.za", {
              target: "_blank",
            })
          }
        >
          Shop Now
        </Button>
      </Grid>
      <Grid
  item
  xs={12}
  md={6}
  sx={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: { xs: "auto", md: "100%" },
    position: "relative", // Add position relative to parent Grid
  }}
>
  <img
    style={{
      width: "50%", // Reduced width
      height: "90%", // Reduced height
      objectFit: "contain",
    }}
    alt=""
    src="/new_dog_banner.jpg"
  />
  <Button
    sx={{
      color: "#fff",
      borderColor: "#ccc",
      background: "#000",
      // color: "#ff6501",
      // borderColor: "#ff6501",
      // background: "#fff",
      "&:hover": {
        backgroundColor: "#ff6501",
        borderColor: "#ff6501",
        color: "white",
      },
      position: "absolute",
      top: "83%",
      left: { xs: "50%", sm: "55%", md: "55%", lg: "55%" },
      transform: "translateX(-50%)", // Center horizontally based on the left value
    }}
    variant="outlined"
    onClick={scrollToTarget}
  >
    promotions
  </Button>
</Grid>

    </Grid>
  );
};

const scrollToTarget = () => {
  const element = document.getElementById('targetElement');
  if (element) {
    window.scrollTo({
      top: element.offsetTop -20,
      behavior: 'smooth',
    });
  }
};
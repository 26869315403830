import React, { useContext, useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
// import axiosInstance from "configs/axiosConfig";
import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import { Link } from "react-router-dom";
import { MyContext } from "../Context/websiteinfocontext";
// import { APIRouteConstants } from "constants/routeConstants";
// import { useSnackbar } from "notistack";
function Footer() {
  const { socialmedia } = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  //   const { enqueueSnackbar } = useSnackbar();
  const [emailError, setEmailError] = useState("");

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  console.log("socialmedia", socialmedia);
  const handleEmailChange = (e) => {
    e.preventDefault();
    // console.log("first", validateEmail(e.target.value));
    if (email === "") {
      setEmailError("Please Enter email address");
      return;
    }
    subscribe(e.target.value);
  };

  const subscribe = async () => {
    let { data, response } = res;
    if (data.status === 200) {
      setLoading(false);
      setEmailError("");
      setEmail("");
      //   enqueueSnackbar("You've subscribed successfully.", {
      //     variant: "success",
      //   });
    } else {
      setLoading(false);
      //   enqueueSnackbar("Invalid email address", { variant: "error" });
    }
  };
  // const validateEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  return (
    <footer className="footer">
      <div className="footer__div">
        <div className="footer__div__top">
          <div className="footer__div__top__aboutus">
            {/* {(socialmedia?.instagram  ||
              socialmedia?.twitter  ||
              socialmedia?.facebook  ||
              socialmedia?.linkedIn ) && } */}
            <div>Connect with us:</div>
            <div style={{ display: "none" }}>
              Webbieshop is from the stable of The Venture Studio who are the
              venture builders and spin out products that solve market problems.
            </div>


            

            <div>
              {socialmedia?.instagram && (
                <a
                href={`https://www.instagram.com/${socialmedia.instagram}`}
                target="_blank"
              >   <i className="bi bi-instagram"></i>
                </a>
              )}
              {socialmedia?.linkedIn && (
                 <a
                 href={`https://www.linkedin.com/in/${socialmedia.linkedIn}`}
                 target="_blank"
               >  <i className="bi bi-linkedin"></i>
                </a>
              )}
              {socialmedia?.twitter && (
                 <a
                 href={`https://twitter.com/intent/user?user_id=${socialmedia.twitter}`}
                 target="_blank"
               >  <i className="bi bi-twitter"></i>
                </a>
              )}{" "}
              {socialmedia?.facebook && (
                <a
                href={`https://facebook.com/profile.php?id=${socialmedia.facebook}`}
                target="_blank"
              >   <i className="bi bi-facebook"></i>
                </a>
              )}
            </div>
          </div>
          <div className="footer__div__top__right">
            <ul className="footer__div__top__right__notifications">
              <li className="footer__div__top__right__notifications__heading">
                COMPANY
              </li>
              <li>
                <Link
                  to={"/about"}
                  style={{ whiteSpace: "nowrap" }}
                  onClick={scrollToTop}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link to={"/contactus"} onClick={scrollToTop}>
                  Contact us
                </Link>
              </li>
              {/* <li>
                <Link
                  to={"/privacypolicy"}
                  style={{ whiteSpace: "nowrap" }}
                  onClick={scrollToTop}
                >
                  Privacy Policy
                </Link>
              </li> */}
              {/* <li>
                <Link
                  style={{ whiteSpace: "nowrap" }}
                  to={"/termsandcondistion"}
                  onClick={scrollToTop}
                >
                  FAQs
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="footer__div__top__right">
            <ul className="footer__div__top__right__notifications">
              <li className="footer__div__top__right__notifications__heading">
                INFORMATION
              </li>
              {/* <li>
                <Link to={"/partners"}>Become our Partner</Link>
              </li> */}
              {/* <li>
                <Link to={"/faq"} onClick={scrollToTop}>
                  FAQs
                </Link>
              </li> */}
              <li>
                <Link to={"/ourteams"} onClick={scrollToTop}>
                  Our Team
                </Link>
              </li>
              <li>
                <Link to={"/ourstores"} onClick={scrollToTop}>
                  Vet Stores
                </Link>
              </li>
              {/* <li>
                <Link to={"/payments"} onClick={scrollToTop}>
                  Payments
                </Link>
              </li> */}
            </ul>
          </div>
          {/* <div className="footer__div__top__right">
            <form
              className="footer__div__top__right__form"
              onSubmit={handleEmailChange}
            >
              <input
                type="text"
                name="email"
                // id="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <p style={{ color: "#DD4498", fontSize: "10px" }}>
                  {emailError}
                </p>
              )}
              {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={15} style={{ color: "#dd4498" }} />
                </div>
              ) : (
                <button type="submit">Subscribe</button>
              )}
            </form>
          </div> */}
        </div>
      </div>
      <div className="footer__div__line"></div>
      <div className="footer__div__bottom">
        <div className="footer__div__bottom__left">
          <span>
            <Link to={"/privacypolicy"} onClick={scrollToTop}>
              Privacy Policy{" "}
            </Link>
          </span>
          <span>|</span>
          <span>
            <Link to={"/termsandcondistions"} onClick={scrollToTop}>
              Terms & Conditions{" "}
            </Link>
          </span>
        </div>
        <div className="footer__div__bottom__right">
          Copyright © 2024 The Vet Store. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;

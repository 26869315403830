import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import _ from "lodash";
import parse from "html-react-parser";
import { LANDING_PAGE } from "../helper/apiEndpoints";
import instance from "../helper/api/axiosconfig";
function PrivacyPolicy() {
  const containerRef = useRef(null);
  const [termsAndCondition, setTermsAndCondition] = useState();
  const [contactus, setContactUs] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const getTermsAndCondition = async () => {
    setLoading(true);
    // let res = await instance.get(LANDING_PAGE.TERMSANDCONDN);
    let res = await instance.get(LANDING_PAGE.PRIVACY_POLICY);
    let { data, response } = res;
    if (response && response.status === 400 && response.data) {
      for (let i in response.data) {
        enqueueSnackbar(_.capitalize(i) + ": " + response.data[i], {
          variant: "error",
        });
      }
    }

    // if (data && data?.terms_and_condition) {
    if (data && data?.privacy_policy) {
      // setTermsAndCondition(data?.terms_and_condition);
      setTermsAndCondition(data?.privacy_policy);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTermsAndCondition();
  }, []);

  if (loading) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress color="primary" style={{ color: "#ff6501" }} />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid
        item
        xs={12}
        sm={12}
        md={10}
        lg={11}
        sx={{
          position: "relative",
          minHeight: "55vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          overflow: "hidden",
        }}
      >
        <img
          src="/pexels-markus-winkler-1430818-12188471.jpg"
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />
        <Typography
          variant="h3"
          component="h1"
          sx={{
            // fontSize: 25,
            fontFamily: "Poppins",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0)", // Optional: adds a slight background to improve text readability
            padding: 1,
            borderRadius: 1,
            zIndex: 1,
          }}
        >
          {/* Our Stores */}
        </Typography>
      </Grid>
      <Grid container justifyContent="center" sx={{ padding: 2 }}>
        <Grid item xs={12} sm={12} md={10} lg={11}>
          <Box
            sx={{
              padding: 2,
              width: "100%",
              backgroundColor: "background.paper",
              boxShadow: 0.5,
              borderRadius: 2,
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              mb={0.5}
              fontSize={25}
              fontFamily={"Poppins"}
            >
              Privacy Policy
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Box>
              <DialogContent className="dialog-content">
                <DialogContentText
                  className="policy-wrapper"
                  id="alert-dialog-slide-description"
                >
                  {termsAndCondition == null ? (
                    <p>
                      We are currently updating our privacy policy. Please check
                      back later for the latest information.
                    </p>
                  ) : (
                    <Box>
                    <div
                      // ref={containerRef}
                      dangerouslySetInnerHTML={{ __html: termsAndCondition }}
                    />
                  </Box>
                    // <>
                    //   <span style={{ whiteSpace: "pre-line" }}>
                    //     {parse(termsAndCondition)}
                    //   </span>
                    //   {/* <p style={{ whiteSpace: "pre-line" }}>
                    //     {_.get(termsAndCondition, "terms_and_condition")}
                    //   </p> */}
                    // </>
                  )}
                </DialogContentText>
              </DialogContent>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default PrivacyPolicy;

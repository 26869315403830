import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter,HashRouter } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
  styled,
} from "@mui/material";

import "./global.css";
import { SnackbarConfigurator } from "./helper/Notify";
import { MaterialDesignContent, SnackbarProvider } from "notistack";

const muiTheme = createTheme();

const container = document.getElementById("root");
const root = createRoot(container);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#dd4498",
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#688ad5",
  },
}));

root.render(
  <HashRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        autoHideDuration={3000}
        maxSnack={3}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
<SnackbarConfigurator />
        <App />

      </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

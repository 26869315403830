import { Grid } from "@mui/material";
import React, { Children, Fragment } from "react";
import FrameComponent1 from "./FrameComponent1";
import GroupComponent2 from "../components/GroupComponent2";
import Footer from "./Footer";

function Layout(props) {
  return (
    <Fragment>
      <Grid>
        <FrameComponent1 />
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
      <Grid>
        <Footer />
      </Grid>
    </Fragment>
  );
}

export default Layout;

//beta
// import { useEffect } from "react";
// import {
//   Routes,
//   Route,
//   useNavigationType,
//   useLocation,
// } from "react-router-dom";
// import ShoppingApp from "./pages/ShoppingApp";
// import About from "./pages/About";
// import Conatct from "./pages/ContactUs";
// import Support from "./pages/Support";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import Faq from "./pages/Faq";
// import Termsandcondistion from "./pages/Termsandcondistion";

// function App() {
//   const action = useNavigationType();
//   const location = useLocation();
//   const pathname = location.pathname;

//   useEffect(() => {
//     if (action !== "POP") {
//       window.scrollTo(0, 0);
//     }
//   }, [action, pathname]);

//   useEffect(() => {
//     let title = "";
//     let metaDescription = "";

//     switch (pathname) {
//       case "/":
//         title = "";
//         metaDescription = "";
//         break;
//     }

//     if (title) {
//       document.title = title;
//     }

//     if (metaDescription) {
//       const metaDescriptionTag = document.querySelector(
//         'head > meta[name="description"]'
//       );
//       if (metaDescriptionTag) {
//         metaDescriptionTag.content = metaDescription;
//       }
//     }
//   }, [pathname]);

//   return (
//     <Routes>
//       <Route path="/" element={<ShoppingApp />} />
//       <Route path="/about" element={<About/>} />
//       <Route path="/privacypolicy" element={<PrivacyPolicy />} />
//       <Route path="/contactus" element={<Conatct />} />
//       <Route path="/support" element={<Support />} />
//       <Route path="/faq" element={<Faq />} />
//       <Route path="/termsandcondistions" element={<Termsandcondistion />} />
//       {/* <Route path="/" element={<ShoppingApp />} />
//       <Route path="/" element={<ShoppingApp />} />
//       <Route path="/" element={<ShoppingApp />} />
//       <Route path="/" element={<ShoppingApp />} /> */}
//     </Routes>
//   );
// }
// export default App;

import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  HashRouter,
} from "react-router-dom";
import ShoppingApp from "./pages/ShoppingApp";
import About from "./pages/About";
import Contact from "./pages/ContactUs";
import Support from "./pages/Support";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Faq from "./pages/Faq";
import TermsAndConditions from "./pages/Termsandcondistion";
import { MyProvider } from "./Context/websiteinfocontext";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { styled } from "@mui/material";
import OurTems from "./pages/OurTems";
import Ourstores from "./pages/Ourstores";
import OurClinics from "./pages/OurClinics";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "The Vet Store";
        metaDescription = "Welcome to the petfood Shopping App.";
        break;
      case "/about":
        title = "About Us";
        metaDescription = "Learn more about us.";
        break;
      case "/privacypolicy":
        title = "Privacy Policy";
        metaDescription = "Read our privacy policy.";
        break;
      case "/contactus":
        title = "Contact Us";
        metaDescription = "Get in touch with us.";
        break;
      case "/support":
        title = "Support";
        metaDescription = "How can we help you?";
        break;
      case "/faq":
        title = "FAQ";
        metaDescription = "Frequently Asked Questions.";
        break;
      case "/termsandconditions":
        title = "Terms and Conditions";
        metaDescription = "Read our terms and conditions.";
        break;
      default:
        title = "Shopping App";
        metaDescription = "Welcome to the Shopping App.";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);


  return (
    <MyProvider>
      <Routes>
        <Route path="/" element={<ShoppingApp />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/support" element={<Support />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/termsandcondistions" element={<TermsAndConditions />} />
        <Route path="/ourteams" element={<OurTems />} />
        <Route path="/ourstores" element={<Ourstores />} />
        <Route path="/ourclinics" element={<OurClinics />} />
      </Routes>
    </MyProvider>
  );
}

export default App;
